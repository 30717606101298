<template>
    <div class="col-12">
        <!-- edudex import list -->

        <recess-table-flex 
            class="qa-table-edudex-import-list w-100"  
            :table-options="setTableOptions"
            :pagination-options="setPaginationOptions"
            @getData="updateList"
        >  
            <recess-table-flex-head :set-fixed-header="true">
                <recess-table-flex-row>
                    <recess-table-flex-head-item
                        v-for="header in headers"
                        :key="header.key"
                        :class="`qa-header-table-${header.title}`"
                        :cell-size="header.cellSize"
                        >{{ header.title }}</recess-table-flex-head-item
                    >
                </recess-table-flex-row>
            </recess-table-flex-head>
            <recess-table-flex-body>
                <recess-table-flex-row
                    v-for="(edudexImport, indextr) in edudexImportList"
                    :key="'edudexImport-' + edudexImport.id"
                    class="qa-edudex-import-list-row"
                >
                    <recess-table-flex-cell
                        :data-label="headers[0].title"
                        :class="`qa-edudex-import-list-date-created-${indextr}`"
                        >{{ edudexImport.dateCreated | formatDate }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell
                        :data-label="headers[1].title"
                        :class="`qa-edudex-import-list-import-type-${indextr}`"
                        >{{ edudexImport.statusDisplayValue }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell
                        :data-label="headers[2].title"
                        :class="`qa-edudex-import-list-email-${indextr}`"
                        cell-size="medium"
                        >{{ setEmailAddress(edudexImport.emailAddress) }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell :data-label="headers[3].title">
                        <div :class="`u-color-success qa-edudex-import-list-succeeded-${indextr}`">
                            {{ setResult(edudexImport) }}
                        </div>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell :data-label="headers[4].title">
                        <div :class="`u-color-error qa-edudex-import-list-invalid-${indextr}`">
                            {{ edudexImport.invalid }}
                            {{
                                PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Invalid
                                    .Description
                            }}
                        </div>
                    </recess-table-flex-cell>
                    <recess-table-flex-cell
                        :data-label="headers[5].title"
                        :class="`qa-edudex-import-list-unmodified-${indextr}`"
                        >{{ edudexImport.unmodified }}
                        {{
                            PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Unmodified.Description
                        }}</recess-table-flex-cell
                    >
                    <recess-table-flex-cell cell-size="medium">
                        <div class="d-flex justify-content-end">
                            <recess-button
                                variant="secondary"
                                :title="BUTTON_TEXT.viewDetails"
                                :class="`qa-edudex-import-list-detail-${indextr}`"
                                @click.native.prevent="gotoEdudexImportDetails(edudexImport.id)"
                            />
                        </div>
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body> 
        </recess-table-flex> 
    </div>
</template>

<script>
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { getItems } from '@/../../shared/api/SharedClient'
import { PAGINATION, API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'

// NEW MIXINS FOR TABLE - REQUIRED
import tableMixin from '@/../../shared/mixins/tableMixin' 

export default {
    mixins: [tableMixin],
    props: {
        setSelectedFilters: {
            type: Object,
            default:() => {
                return {}
            }
        }
    },
    data() {
        // TABLE AND PAGINATION OPTIONS - REQUIRED
        let paginationOptions = [
            { displayText: '5', value: 5 },
            { displayText: '10', value: 10 },
            { displayText: '25', value: 25 },
            { displayText: '50', value: 50 },
            { displayText: '100', value: 100 }, 
            { displayText: '250', value: 250 }
        ]
        
        return {
            PROVIDER,
            PAGINATION,
            BUTTON_TEXT,  
            isCompletedEdudexFeed: false,
            edudexImportList: [],
            filter: {
                search: '',
                top: 0,
                skip: 0,
                orderBy: ['utcDateCreated desc'],
                filter: '',
                searchMode: 'all'
            },
            headers: [
                { key: 'utcDateCreated', title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableHeaders.DateCreated },
                { key: 'status', title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableHeaders.Status },
                { key: 'emailAddress', title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableHeaders.EmailAddress, cellSize: 'medium' },
                { key: 'resultSuccess', title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableHeaders.Result },
                { key: 'resultInvalid', title: '' },
                { key: 'resultUnmodified', title: '' },
                { key: '', title: '', cellSize: 'medium' }
            ], 

            // TABLE AND PAGINATION OPTIONS - REQUIRED
            setTableOptions: {
                title: PROVIDER.Portfolio.EdudexFeed.ImportList.TableTitle,
                setCellSizes: true 
            },
            setPaginationOptions: { 
                showTopPagination: true, 
                showBottomPagination: true, 
                currentPage: this.$route.query.page !== undefined ? Math.round(Number(this.$route.query.page)) : 1,
                totalCount: null,
                selectOptions: paginationOptions,
                defaultTotalItemsPerPage: paginationOptions[1].value,
                itemsPerPageFromUrlQuery: this.$route.query.itemsPerPage !== undefined ? Number(this.$route.query.itemsPerPage) : paginationOptions[1].value,
                showSelectOption: true, 
                selectOptionsLabel: PAGINATION.selectOptionsLabel,
                paginationShowLabel: PAGINATION.paginationShowLabel,
                paginationOfLabel: PAGINATION.paginationOfLabel 
            } 
        }
    },
    mounted() { 
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {  
            this.$bus.on('edudexFeedProgress', this.validateEdudexFeedCompleted)
        })
    },
    beforeDestroy() { 
        this.$bus.off('edudexFeedProgress')
    }, 
    methods: {
        gotoEdudexImportDetails(edudexImportId) {
            if (!edudexImportId) return
            this.$router.push({
                name: 'edudex-import-details',
                params: { id: edudexImportId }
            })
        },
        setResult(edudexImport) {
            return edudexImport.status ===
                this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value
                ? `${edudexImport.succeeded} ${this.PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Succeeded.Description}`
                : `${edudexImport.valid} ${this.PROVIDER.Portfolio.EdudexFeed.ImportProgramStatus.Valid.Description}`
        },
        isTest(importItem) {
            return (
                importItem.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.PendingStart.Value &&
                importItem.validationProgress === 100
            )
        },
        isImport(importItem) {
            return (
                importItem.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value &&
                importItem.importProgress === 100
            )
        },
        isInvalid(importItem) {
            return (
                importItem.total === 0 ||
                importItem.status ===
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Invalid.Value ||
                (importItem.status !==
                    this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.PendingStart.Value &&
                    importItem.status !==
                        this.PROVIDER.Portfolio.EdudexFeed.ImportStatus.Completed.Value)
            )
        },
        setEmailAddress(emailAddress) {
            return emailAddress === this.PROVIDER.Portfolio.EdudexFeed.ImportList.AutoImportEmail
                ? this.PROVIDER.Portfolio.EdudexFeed.ImportList.Automatic
                : emailAddress
        },
        validateEdudexFeedCompleted(value) {
            this.isCompletedEdudexFeed = value
            this.updateImportList()
        },
        updateImportList() {
            if (this.isCompletedEdudexFeed) {
                this.getDataOnPageChange()
                this.isCompletedEdudexFeed = false
            }
        },
        updateList({currentPage, itemsPerPage}) { 
            this.setPaginationOptions.itemsPerPageFromUrlQuery = itemsPerPage
            this.setPaginationOptions.currentPage = currentPage

            this.filter.filter = ''
            this.filter.top = itemsPerPage
            this.filter.skip = currentPage * itemsPerPage - itemsPerPage
            this.filter.currentPage = currentPage            
            
            this.getSelectedProvider()

            // Add this everytime you need to concatenate filters
            this.concatenateFilterString()
            // -- 

            this.getIsAutoStart() 

            // Get data from api  
            this.getDataOnPageChange() 
        },
        getSelectedProvider() {
            if (!(this.setSelectedFilters && this.setSelectedFilters.providerId)) return
            this.filter.filter += ` providerId eq ${this.setSelectedFilters.providerId}`
        },
        getDataOnPageChange() {    
            this.getEdudexImportsList() 

            this.setCurrentPageAsQueryParam()
           
        },
        async getEdudexImportsList() { 
            try {
                const response = await getItems(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.edudexImports,
                    this.filter.currentPage,
                    this.filter.top,
                    this.filter.orderBy,
                    this.filter.filter,
                    false
                )
                if (!response || !response.items) return
                this.edudexImportList = response.items 
                this.setPaginationOptions.totalCount = response.metadata.pagination.total 
            } catch (error) {
                console.error('Something went wrong while retrieving edudex imports list', error)
            }
        },
        getIsAutoStart() { 
            this.filter.filter += 'isAutoStart eq true'
        },
        concatenateFilterString() {
            if (this.filter.filter.length > 0) {
                this.filter.filter += ` and `
            }
        }
    }
}
</script>
